<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="实地调查情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">项目名称</div>
        </el-row>
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              项目基本情况
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <div class="leftTitletStyle">计划项目（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">计划投资（万元）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民乡（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民村（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民组（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              前期工作
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <div class="leftTitletStyle">规划内项目（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">公开公示项目（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">征求移民意愿项目（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">文件(建议书、实施方式、可研报告)（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              实施主体
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <div class="leftTitletStyle">县级移民管理部门（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">县级行业主管部门（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">乡镇政府（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">村委会（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">其他（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">委托方式</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">公开招标（个）</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">应执行（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">实际执行（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">公开比选（个）</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">应执行（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">实际执行（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <div class="leftTitletStyle">其他（个）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">实施管理</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">变更情况</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">变更项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">履行变更程序的项目（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">合同签订（个）</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">签订（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">建设监理</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">应执行（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">实际执行（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <div class="leftTitletStyle">开工情况（个）</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <div class="leftTitletStyle">完工情况（个）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">验收移交</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">验收情况</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">验收（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">验收合格（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">移交情况（个）</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">应移交（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">实际移交（个）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">项目档案管理</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">档案管理规范（个）</div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <!-- 项目基本情况 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('plannedProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('plannedInvestment') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('affectedTownships') }}</div>
          </div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('affectedVillages') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('affectedGroups') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 前期工作 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('plannedProjectsInPlan') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('publicizedProjects') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('migrationProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('documentsCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 实施主体 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('countyMigrationDepartment') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('countySupervisoryDepartment') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('townshipGovernment') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('villageCommittee') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherImplementingEntities') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 实施主体 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('publicTenderToBeExecuted') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('publicTenderActuallyExecuted') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('publicComparisonToBeExecuted') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('publicComparisonActuallyExecuted') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherEntrustment') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 实施管理 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('changedProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('projectsWithChangeProcedure') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('contractSignedProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('supervisionToBeExecuted') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('supervisionActuallyExecuted') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('startedProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('completedProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 实施主体 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('projectsForAcceptance') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('acceptanceQualifiedProjects') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('toBeTransferredProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('actuallyTransferredProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('standardizedFileManagement') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>

                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <!-- 项目基本内容 -->
                        <el-row v-if="key1 == 'plannedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'plannedInvestment'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedTownships'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedVillages'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedGroups'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 前期工作 -->
                        <el-row v-if="key1 == 'plannedProjectsInPlan'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicizedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'migrationProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'documentsCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 实施主体 -->
                        <el-row v-if="key1 == 'countyMigrationDepartment'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'countySupervisoryDepartment'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'townshipGovernment'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'villageCommittee'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'otherImplementingEntities'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 委托方式 -->
                        <el-row v-if="key1 == 'publicTenderToBeExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicTenderActuallyExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicComparisonToBeExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicComparisonActuallyExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'otherEntrustment'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 实施管理 -->
                        <el-row v-if="key1 == 'changedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'projectsWithChangeProcedure'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'contractSignedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'supervisionToBeExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'supervisionActuallyExecuted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'startedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'completedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 验收移交 -->
                        <el-row v-if="key1 == 'projectsForAcceptance'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'acceptanceQualifiedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'toBeTransferredProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'actuallyTransferredProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'standardizedFileManagement'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _ProjectFieldworkAdd,
  _ProjectFieldworkInfo,
  _ProjectFieldworkEdit
} from '@/api/modular/postImmigrationService/policy/fieldInvestigation.js'
export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      console.log(obj, '=======handleChange======')
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }

      return sum
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            plannedProjects: null,
            plannedInvestment: null,

            affectedTownships: null,
            affectedVillages: null,
            affectedGroups: null,

            plannedProjectsInPlan: null,
            publicizedProjects: null,

            migrationProjects: null,
            documentsCount: null,
            countyMigrationDepartment: null,
            countySupervisoryDepartment: null,

            townshipGovernment: null,
            villageCommittee: null,
            otherImplementingEntities: null,

            publicTenderToBeExecuted: null,
            publicTenderActuallyExecuted: null,
            publicComparisonToBeExecuted: null,
            publicComparisonActuallyExecuted: null,
            otherEntrustment: null,

            changedProjects: null,
            projectsWithChangeProcedure: null,
            contractSignedProjects: null,

            supervisionToBeExecuted: null,
            supervisionActuallyExecuted: null,

            startedProjects: null,
            completedProjects: null,

            projectsForAcceptance: null,
            acceptanceQualifiedProjects: null,
            toBeTransferredProjects: null,
            actuallyTransferredProjects: null,
            standardizedFileManagement: null,
            countyPids: ''
          }

          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let flag = this.listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _ProjectFieldworkAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _ProjectFieldworkEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _ProjectFieldworkInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.listData = this.formateResData(arr)
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          countyId: ele.countyId, //县（市区）
          county: ele.county, //县（市区）

          plannedProjects: ele.plannedProjects,
          plannedInvestment: ele.plannedInvestment,

          affectedTownships: ele.affectedTownships,
          affectedVillages: ele.affectedVillages,
          affectedGroups: ele.affectedGroups,

          plannedProjectsInPlan: ele.plannedProjectsInPlan,
          publicizedProjects: ele.publicizedProjects,

          migrationProjects: ele.migrationProjects,
          documentsCount: ele.documentsCount,
          countyMigrationDepartment: ele.countyMigrationDepartment,
          countySupervisoryDepartment: ele.countySupervisoryDepartment,

          townshipGovernment: ele.townshipGovernment,
          villageCommittee: ele.villageCommittee,
          otherImplementingEntities: ele.otherImplementingEntities,

          publicTenderToBeExecuted: ele.publicTenderToBeExecuted,
          publicTenderActuallyExecuted: ele.publicTenderActuallyExecuted,
          publicComparisonToBeExecuted: ele.publicComparisonToBeExecuted,
          publicComparisonActuallyExecuted: ele.publicComparisonActuallyExecuted,
          otherEntrustment: ele.otherEntrustment,

          changedProjects: ele.changedProjects,
          projectsWithChangeProcedure: ele.projectsWithChangeProcedure,
          contractSignedProjects: ele.contractSignedProjects,

          supervisionToBeExecuted: ele.supervisionToBeExecuted,
          supervisionActuallyExecuted: ele.supervisionActuallyExecuted,

          startedProjects: ele.startedProjects,
          completedProjects: ele.completedProjects,

          projectsForAcceptance: ele.projectsForAcceptance,
          acceptanceQualifiedProjects: ele.acceptanceQualifiedProjects,
          toBeTransferredProjects: ele.toBeTransferredProjects,
          actuallyTransferredProjects: ele.actuallyTransferredProjects,
          standardizedFileManagement: ele.standardizedFileManagement,

          countyPids: ele.countyPids,
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
